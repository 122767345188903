<template>
  <div class="allView winStyle">
    <!-- 测点 触摸弹框-->
    <!-- :class="targetBox == 1 ? 'ztop' : 'zBottom'" -->
    <div id="targetBox" class="ztop">
      <!-- <targetWin v-if="typeAllFlag"></targetWin> -->
      <div class="targetWin" v-if="typeAllFlag" @click="targetBoxClick">
        <div>
          <div class="color1 mT10 mB10" style="margin-left: 17px">{{ list.code }}:{{ list.site }}</div>
          <div class="imgBox">
            <img :src="targetBoxImgSrc" alt />
          </div>
          <div class="box justify" style="margin-left: 40px; margin-right: 40px; margin-top: 17px">
            <table width="100%" border="1">
              <tr class="color1">
                <td width="100px">{{ list.typeName }}</td>
                <td>{{ list.installTime }}</td>
                <td>{{ list.status | statusData }}</td>
              </tr>
              <tr class="color2">
                <td>监测指标类型</td>
                <td>设备安装时间</td>
                <td>设备状态</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- 单桥信息 -->
    <div style="z-index: 999">
      <aloneLook ref="alonelookzhw" @getAloneWarn="getAloneWarn" :warnList="warnList" ></aloneLook>
    </div>
    <!-- 数据图表展示 -->
    <div id="largeWin" class="dataWin" :class="closeShow == 1 ? 'a' : 'b'" v-if="closeShow">
      <dataShow :closeShow="closeShow" @getAdd="getAdd"></dataShow>
    </div>
    <!-- 视频窗口 -->
    <div class="videoWin" :class="closeVideo == 1 ? 'a' : 'b'">
      <videoWin :src="src" :closeVideo="closeVideo" @getAdd1="getAdd1" ></videoWin>
    </div>
    <!-- 轻度预警-->
    <div class="main">
      <!-- 左上角 -->
      <div v-if="all == -1" id="leftTop1" class="leftTop1">
        <div class="left1 mB10">
          <div>桥梁群总览</div>
          <div class="box flexBottom">
            <div class="fs32">{{ numberAll.count }}</div>
            <div>座</div>
          </div>
          <div class="box flexBottom">
            <div class="fs32">{{ numberAll.length }}</div>
            <div>米</div>
          </div>
        </div>
        <div class="left2">
          <ul>
            <li v-for="(item, index) in numberList" :key="index">
              <div>
                <div>{{ item.name }}</div>
              </div>
              <div class="box center">
                <div style="margin-right: 13px">
                  <img :src="item.img" alt />
                </div>
                <div class="fs36">{{ item.number }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 左侧 2  -->
      <div v-if="all == 1" class="leftTop2">
        <!-- 桥梁预警状态列表 弹框 -->
        <div class="newTree warningzhw" v-if="warningSelect == 1">
          <div class="winTitle box justify" :class="bottom == 1 ? 'winTitle' : 'winTitle1'">
            <div>桥梁预警列表</div>
          </div>
          <div class="winBottom" v-if="bottom == 1">
            <el-tree ref="tree" :data="warningData" :default-expand-all="true" :props="defaultProps" node-key="id" :current-node-key="currentNodeKey" :highlight-current="true">
              <span class="custom-tree-node textL" slot-scope="{ node, data }">
                <span>{{ data.name }}</span>
                <span v-if="data.parentId == '0'">({{ data.list.length }})</span>
              </span>
            </el-tree>
          </div>
        </div>
      </div>

      <!--单桥预警状态 -->
      <!-- <div class="leftTop22">
        <ul @click="showAloneLook">
          <li v-for="(item, index) in warnList" :key="index" class="box around">
            <div class="box center">
              <div style="width: 16px; height: 22px" class="mR5">
                <img :src="item.src" alt style="width: 100%; height: 100%" />
              </div>
              <div class="box flexBottom">
                <div class="fs16" style="margin-right: 6px">{{ item.name }}</div>
                <div class="fs22">{{ item.number }}</div>
                <div class="fs12">个</div>
              </div>
            </div>
          </li>
        </ul>
      </div> -->

      <!-- 右侧导航 -->
      <div class="operation" v-show="isLoadModel">
        <!-- 重定向， 放大，缩小 -->
        <div class="maxmin box around">
          <div>
            <div class="mB10 yuan1 pointer box around">
              <!-- <div class="iconfont icon-fangda_icon" @click="gisData('resetFly')"></div> -->
              <el-tooltip popper-class="tps" content="复位" placement="left">
                <img src="../../assets/images/all/locate.png" alt @click="gisData('resetFly')" />
              </el-tooltip>
            </div>
            <div class="mB10 yuan pointer" @click="gisData('cameraforward')">
              <el-tooltip popper-class="tps" content="放大" placement="left">
                <img src="../../assets/images/all/fangda.png" alt />
              </el-tooltip>
            </div>
            <div class="yuan pointer" @click="gisData('cameraback')">
              <el-tooltip popper-class="tps" content="缩小" placement="left">
                <img src="../../assets/images/all/suoxiao.png" alt />
              </el-tooltip>
            </div>
          </div>
        </div>
        <!-- 监视器视频按钮 -->
        <div style="margin-top: 10px" v-show="isShowTvBtn" class="pointer" @click.stop="tvShow">
          <el-tooltip popper-class="tps" content="视频监控" placement="left">
            <img src="../../assets/images/all/jianshiqi.png" alt />
          </el-tooltip>
        </div>
      </div>
      <!-- 桥梁 弹框 -->
      <!-- <div class="win newTree" v-if="win == 1 && all == 1" v-clickoutside="handleClose"> -->
      <div class="win newTree" v-if="false" v-clickoutside="handleClose">
        <div class="winTitle box justify" :class="bottom == 1 ? 'winTitle' : 'winTitle1'">
          <div>桥梁监测物列表</div>
        </div>
        <div class="winBottom" v-if="bottom == 1">
          <div class="fu">
            <el-input v-model="name" placeholder="请输入桥梁名称"></el-input>
            <div class="zi pointer" @click="bridgeTreeData">
              <img src="../../assets/images/all/sousuo.png" alt />
            </div>
          </div>
          <el-tree ref="tree" :data="data" :default-expand-all="true" :props="defaultProps" node-key="id" :current-node-key="currentNodeKey" :highlight-current="true" @node-click="handleNodeClick">
            <span class="custom-tree-node textL" slot-scope="{ node, data }">
              <span>{{ data.name }}</span>
              <span v-if="data.parentId == '0'">({{ data.list.length }})</span>
            </span>
          </el-tree>
        </div>
      </div>

      <!-- 视频监控 -->
      <div class="win1" v-if="win1 == 1" v-clickoutside="handleClose">
        <div class="home-title box justify">
          <span class="home-title-name">视频监控</span>
          <img src="@/assets/images/home/guanbi_button.png" alt="" @click.stop="handleVideoClose" />
        </div>

        <!-- 萤石云 -->
        <div id="myVideo" v-if="projectName == 'shuohuang' || projectName == 'huihe'"></div>

        <div class="home-title-content noVideo box around" v-if="projectName != 'shuohuang' || projectName == 'huihe'">
          <div class>
            <img src="../../assets/images/alone/ic_jiankong_zanwu.png" alt />
            <div>暂无视频</div>
          </div>
        </div>
      </div>

      <!-- 总览桥型类型 -->
      <div class="qiaoType" v-if="qiaoType == 1">
        <div class="top" style="height: 40px; line-height: 40px">设备类型</div>
        <div class="bottom" style="width: 248px; height: 63px">
          <ul class="ul1 box">
            <li v-for="(item, index) in queryType" :key="index" @click="changeType(item)" class="pointer">
              <div class="box box_column" :class="item.tid == 3 || 4 ? 'kuandu' : ''">
                <div class="box around center li1" :class="typeAll.includes(item.tid) == true ? 'acLi' : ''">
                  <div class="iconfont box around gong" :class="item.icon" alt></div>
                </div>
                <div class="ul1Title fs12">{{ item.tname }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 单桥类型  aloneType -->
      <div class="aloneType" v-if="aloneType == 1">
        <div class="home-title">
          <span class="home-title-name">测点筛选</span>
          <img src="@/assets/images/home/guanbi_button.png" @click="handleCeDianClose" alt="" />
        </div>
        <div class="home-title-content">
          <div class="off_type">
            <el-checkbox v-model="offtype" @change="offTypeFun">
              <span class="fs14">全选</span>
            </el-checkbox>
          </div>
          <div v-for="(item, index) in aloneData" :key="index">
            <div class="top_1" v-if="item.list && item.list.length">
              <img src="@/assets/images/home/cedianzhuangshi.png" alt="" />
              {{ item.name }}
            </div>
            <div class="bottom_1 box flexW" v-if="item.list && item.list.length">
              <div v-for="(item1, index1) in item.list" :key="index1" class="itemBox box box_column center pointer" @click="getType(item1)">
                <!-- <div class="itemBox1">{{index1}}</div> -->
                <div class="itemBox1 box center around" :class="aloneTypeArr.includes(item1.id) == true ? 'itemBox2' : ''">
                  <span style="color: #c3d2f6" class="iconfont" :class="item1.icon"></span>
                </div>
                <div class="fs10">{{ item1.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 运维期桥梁状态切换 -->
      <div class="changeDitu pointer" @click="gisData('stateChange')" v-show="isLoadModel">
        <el-tooltip popper-class="tps" :content="bridgeState === 1 ? '桥梁观察状态' : '测点查看状态'" placement="left">
          <div v-if="bridgeState === 1">
            <img src="../../assets/images/all/uncheck-test-state.png" alt />
          </div>
          <div v-else>
            <img src="../../assets/images/all/checked-test-state.png" alt />
          </div>
        </el-tooltip>
      </div>

      <!-- 搜索类型 -->
      <div class="listSearch pointer" @click="typeShow" v-show="isLoadModel">
        <el-tooltip popper-class="tps" content="测点筛选" placement="left">
          <div v-if="aloneType == 0">
            <img src="../../assets/images/all/uncheck-device.png" alt />
          </div>
          <div v-else>
            <img src="../../assets/images/all/checked-device.png" alt />
          </div>
        </el-tooltip>
      </div>
    </div>
    <!--unity 模型  -->
    <div class="model-wrapper">
      <div class="img-wrapper" v-if="!isLoadModel" :style="{ backgroundImage: `url(${modelImgSrc})`, backgroundSize: 'cover' }">
        <div class="load-model-wrapper" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
          <img :src="loadModelImg" alt="" @click="handleLoadModelBtnClick" />
          <span :class="isHover ? 'checked-text' : 'normal-text'">加载模型</span>
        </div>
      </div>
      <iframe v-show="isLoadModel" :src="unityUrl" id="unity-model" width="100%" height="100%" frameborder="0" ref="unityInstance"></iframe>
    </div>
  </div>
</template>

<script>
import videojs from "video.js";
import dataShow from "../../components/alone/dataShow.vue";
import videoWin from "../../components/allView/videoWin.vue"; //视频窗口
// import targetWin from "./targetWin.vue"; //单桥的测点弹框
import aloneLook from "./aloneLook.vue"; //单桥样式
import qs from "qs";

const clickoutside = {
  // 初始化指令
  bind(el, binding, vnode) {
    function documentHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false;
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e);
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.__vueClickOutside__ = documentHandler;
    document.addEventListener("click", documentHandler);
  },
  unbind(el, binding) {
    // 解除事件监听
    document.removeEventListener("click", el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  },
};

import EZUIKit from "ezuikit-js";
export default {
  name: "allView",
  directives: { clickoutside },
  components: {
    dataShow,
    videoWin, //视频窗口
    aloneLook, //单桥信息
    // targetWin
  },
  prod:{
    isLoad3d:false
  },
  watch: {
    // 如果all =0； 就是进入单桥了。 发送给导航
    all(n, o) {
      console.log("n,o", n, o);
      if (n == 0) {
        //0进入单桥
        console.log("进入单桥 监听");
        this.qiaoType = 0; //隐藏总览类型
        this.aloneType = 1; //显示单桥类型
        this.aloneTypeData(sessionStorage.getItem("projectId")); //单桥类型
        this.$bus.$emit("all", "0");
        sessionStorage.setItem("all", 0);
        this.win = 0;
      } else {
        if (this.$refs["alonelookzhw"]) {
          this.$refs["alonelookzhw"].getClearRate();
        }
        this.$bus.$emit("all", "0");
        sessionStorage.setItem("all", 0);
      }
    },
    // isLoad3d(val){
    //   if(val){
    //     this.$refs.unityInstance.contentWindow.Set_SystemDisplayState(1);
    //   }else{
    //     this.$refs.unityInstance.contentWindow.Set_SystemDisplayState(0);
    //   }
      
    // }
  },

  data() {
    return {
      dadawe: 10000,
      isShowTvBtn: false,
      offtype: false,
      typeAllFlag: false, // 触点弹窗
      warningSelect: 0,
      numberAll: {}, //总座数，总长度
      numberList: [
        {
          id: "1",
          name: "悬索桥（座）",
          number: "",
          img: require("../../assets/images/all/qiao_type1.png"),
        },
        {
          id: "2",
          name: "斜拉桥（座）",
          number: "",
          img: require("../../assets/images/all/qiao_type2.png"),
        },
        {
          id: "3",
          name: "梁桥（座）",
          number: "",
          img: require("../../assets/images/all/qiao_type3.png"),
        },
        {
          id: "4",
          name: "梁桥（座）",
          number: "",
          img: require("../../assets/images/all/qiao_type4.png"),
        },
      ], // 左侧右上角 桥类型列表
      queryType: [], //桥梁类型
      projectId: sessionStorage.getItem("projectId"),
      typeAll: [], //桥梁选中
      warningData: [],
      warnList: [
        {
          id: "1",
          name: "一级超限",
          number: "",
          src: require("../../assets/images/all/qingduyujing_icon1.png"),
        },
        {
          id: "1",
          name: "二级超限",
          number: "",
          src: require("../../assets/images/all/qingduyujing_icon2.png"),
        },
        {
          id: "1",
          name: "三级超限",
          number: "",
          src: require("../../assets/images/all/qingduyujing_icon3.png"),
        },
      ],
      name: "", //桥梁搜索
      data: [
        {
          id: "1",
          path: "/1",
          name: "导航一",
          parentId: "0",
          icon: "el-icon-menu",
          children: [
            {
              id: "11",
              path: "/1/1",
              name: "导航1-1",
              icon: "el-icon-menu",
            },
            {
              id: "12",
              path: "/1/2",
              name: "导航1-2",
              icon: "el-icon-menu",
            },
          ],
        },
        {
          id: "2",
          path: "/2",
          name: "导航二",
          icon: "el-icon-menu",
          parentId: "0",
          children: [
            {
              id: "21",
              path: "/1/1",
              name: "导航1-1",
              icon: "el-icon-menu",
            },
          ],
        },
        {
          id: "3",
          path: "/1",
          name: "导航一",
          parentId: "0",
          icon: "el-icon-menu",
          children: [
            {
              id: "31",
              path: "/1/1",
              name: "导航1-1",
              icon: "el-icon-menu",
            },
            {
              id: "32",
              path: "/1/2",
              name: "导航1-2",
              icon: "el-icon-menu",
            },
          ],
        },
      ], //桥梁树形

      defaultProps: {
        children: "list",
        label: "name",
      },
      currentNodeKey: "", //默认选中
      win: "0", //1显示  0不显示   桥梁搜索
      bottom: "1", //1显示 0不显示
      win1: "0", //1显示， 0不显示   监视器

      // 视频列表数据
      src: "",
      videoList: [],
      closeVideo: "0", //弹出视频窗口
      value: "", //视频选中
      options: [], //视频树
      timer: null,
      closeShow: "0", //曲线窗口
      qiaoType: "0", //桥梁类型  0 1

      // 请求数据定义
      all: "0", //是否显示总览  0单桥  1总览
      aloneType: "0", //单桥的类型弹窗
      aloneData: [],
      aloneTypeArr: [], //单桥类型选中

      targetBox: "0", //0不显示 2显示 单桥触摸弹框

      srcPath: "", //正式
      // srcPath:'https://www.thingjs.com/pp/4ace010ee17c0cb185ee8dcd',   //测试
      videoToken: "",
      projectName: "",
      unityUrl: "",
      bridgeState: 1, // 1:桥梁观察状态 0：测点查看状态

      /////////////////////////////
      list: {},
      targetBoxImgSrc: "",

      isHover: false,
      isLoadModel: false, // 是否加载模型
      loadModelImg: "", // 鼠标移入移出
      modelImgSrc: "", // 模型2d图片加载 （根据外观配置模块总览背景图获取）
    };
  },
  destroyed() {
    window.removeEventListener("message", this.postMessageFun, false);
  },
  //创建
  created() {
    this.getCoverImg();
    this.srcPath = this.srcPATH; //-----------*********读取gis配置文件

    this.$bus.$on("largeWin", this.largeWin); //接收放大的命令
    this.$bus.$on("centerTarget", this.centerTarget); //从cloneLook转过来的id号

    this.projectName = sessionStorage.getItem("name");
    // if(this.isLoadModel) {
    //   setTimeout(() => {
    //     window.addEventListener("message", this.postMessageFun, false); //监听gis地图
    //     this.unityUrl = "./static/UniversalBridge_Maintain/index.html";
    //     this.$refs.unityInstance.contentWindow.Get_UserToken();
    //     this.$refs.unityInstance.contentWindow.Get_ProjectId();
    //   }, 500);
    // }

    //  监听桥梁列表点击传过来的事件
    this.$bus.$on("handleNodeClick", this.handleNodeClick);
  },

  //安装
  mounted() {
    this.loadModelImg = require("@/assets/images/all/loadModelBtn.png");
    // if (this.isLoadModel) {
    //   this.getToken(); //传递token给gis
    //   window.addEventListener("message", e => {
    //     if (e.data.clickDevice !== "") {
    //       console.log(`模型加载完成-------------`, e, e.data.clickDevice);
    //       // this.$bus.$emit("targetHover2", e.data.clickDevice); //触摸传感器，获取到id
    //       // this.$bus.$emit("targetBoxClick", this.typeAllFlag);
    //       // this.targetHover(e.data.clickDevice);
    //       this.$bus.$emit("getTargetId", e.data.clickDevice);
    //     }
    //   });
    // }

    //左侧告警的位置变化
    // this.getSynthesisVideo(); //查询视频列表
    this.bridgeStatistics(); //桥梁数字统计
    this.warnInter();
    this.bridgeTreeData(); //桥梁树形展示
    // this.bridgeTypyList(); //桥梁类型
    let projectId = sessionStorage.getItem("projectId");
    this.aloneTypeData(projectId); //获取单桥类型
    this.getData(projectId);

    sessionStorage.setItem("all", this.all);
  },

  methods: {
    // getSynthesisVideo() {
    //   this.$axios.get(`${this.baseURL}synthesis/video/${this.projectId}`).then((res) => {
    //     var resData = res.data.data;
    //     if (resData && resData.length) {
    //       this.isShowTvBtn = true;
    //     } else {
    //       this.isShowTvBtn = false;
    //     }
    //   });
    // },
    //////////////////////////////////////////////////////////  dialog
    // 根据项目配置模块的桥梁封面，获取展示图片（一进入该页面首先显示该图片）
    // 点击“加载模型”按钮，才会进行模型加载
    getCoverImg() {
      this.$axios.get(`${this.baseURL}sys/sysImages/cover/${this.projectId}`).then((res) => {
        console.log("获取桥梁封面--图片id", res);
        let detail = res.data.data;
        if (detail[0].id) {
          let imgId = detail[0].id;
          this.modelImgSrc = `${this.baseURL}sys/sysImages/img/${imgId}`;
        }
      });
    },

    // 点击加载模型按钮
    handleLoadModelBtnClick() {
      this.isLoadModel = !this.isLoadModel;
      if (this.isLoadModel) {
        // 加载模型
        window.addEventListener("message", this.postMessageFun, false); //监听gis地图
        this.unityUrl = "./static/UniversalBridge/index.html";
        sessionStorage.setItem("bridgeState", "Maintenance");
        this.$refs.unityInstance.contentWindow.Get_UserToken();
        this.$refs.unityInstance.contentWindow.Get_ProjectId();
        this.$refs.unityInstance.contentWindow.Get_ProjectTypeState();
        // this.$refs.unityInstance.contentWindow.Set_SystemDisplayState(1);
        setTimeout(() => {
          // this.getToken(); //传递token给gis
          window.addEventListener("message", (e) => {
            if (e.data.clickDevice !== "") {
            //  this.$refs.unityInstance.contentWindow.Set_SystemDisplayState(1);
              console.log(`模型加载完成-------------`, e, e.data.clickDevice);
              this.$bus.$emit("getTargetId", e.data.clickDevice);
            }
          });
        }, 500);
      }
    },
    // 鼠标移入
    handleMouseOver() {
      this.isHover = true;
      this.loadModelImg = require("@/assets/images/all/checkLoadModelBtn.png");
    },
    // 鼠标移出
    handleMouseLeave() {
      this.isHover = false;
      this.loadModelImg = require("@/assets/images/all/loadModelBtn.png");
    },

    targetHover(deviceID) {
      let data = {
        targetId: deviceID,
      };
      console.log("传感器id====", deviceID);
      this.$axios
        .post(`${this.baseURL}pandect/target/info/${deviceID}`, data)
        .then((res) => {
          console.log("传感器信息", res.data.data);
          this.list = res.data.data;
          if (res.data.data.length !== 0) {
            this.typeAllFlag = true;
          }
          this.imgData(this.list.id);
        })
        .catch((err) => {});
    },

    imgData(id) {
      // 根据传感器id查图片id
      this.$axios
        .get(`${this.baseURL}sys/sysImages/target/${id}`)
        .then((res) => {
          console.log("图片id", res.data.data);
          var data = res.data.data;
          if (data.length > 0) {
            this.showImg(data[0].id);
          }
        })
        .catch((err) => {});
    },

    showImg(id) {
      this.targetBoxImgSrc = `${this.baseURL}sys/sysImages/img/${id}`;
    },
    //隐藏box
    targetBoxClick() {
      this.typeAllFlag = false;
    },
    //////////////////////////////////////////////////////////

    // 点击预警显示弹窗
    showAloneLook() {
      console.log(this.$refs);
      this.$refs.alonelookzhw.tab(4);
    },

    // 萤石云视频
    getVideo() {
      this.tokenData();
      setTimeout(() => {
        let arr = [
          { src: "ezopen://open.ys7.com/G14450541/1.live" }, // 朔黄桥梁视频
        ];

        var player1 = new EZUIKit.EZUIKitPlayer({
          id: "myVideo", // 视频容器ID
          accessToken: this.videoToken,
          url: arr[0].src,
          width: "100%",
          height: 340,
        });
        player1.play();
      }, 500);
    },
    tokenData() {
      console.log("获取token");
      var data = "appKey=a2f0529eddfc4726bc46ab78b9b15609&appSecret=46f68bd6322b7ea2ac11b7843715c389";
      this.$axios
        .post(`https://open.ys7.com/api/lapp/token/get?${data}`)
        .then((res) => {
          console.log("获取token", res.data.data);
          this.videoToken = res.data.data.accessToken;
        })
        .catch((err) => {});
    },

    // 给gis传递token
    getToken() {
      var token = sessionStorage.getItem("token");
      let OIframe = document.getElementById("unity-model"); //监听gis
      let childDomain = this.srcPath;
      console.log("allview", token, OIframe, childDomain, this.srcPath);
      OIframe.contentWindow.postMessage({ gis_token: token }, childDomain);
    },

    // 获取信息
    getData(id) {
      var data = {
        bid: id,
      };
      this.$axios
        .post(`${this.baseURL}pandect/select/bridge`, qs.stringify(data))
        .then((res) => {
          console.log("桥梁信息", res.data.data);
          this.$store.commit("getProjectName", res.data.data.bridge_name);
          sessionStorage.setItem("projectName", res.data.data.bridge_name);

          this.$store.commit("getProjectCompay", res.data.data.compayName); //桥梁公司名称  getProjectCompay
          sessionStorage.setItem("projectCompay", res.data.data.compayName); //桥梁公司名称  compayName

          this.$store.commit("getProjectId", id); //项目id发送
          sessionStorage.setItem("projectId", id);
          this.projectId = id;

          this.$bus.$emit("getProjectId", id);
        })
        .catch((err) => {});
    },

    // 关闭测点筛选
    handleCeDianClose() {
      this.aloneType = 0;
    },
    //监听gis  传过来的数据
    postMessageFun(e) {
      // 隐藏
      // 监听gis模型渲染完成
      if (e.data.gis_token) {
        console.log("gis_token", e.data.gis_token);
        // this.typeAllFlag = false;
        this.unityUrl = "./static/UniversalBridge/index.html";
        this.$refs.unityInstance.contentWindow.Get_UserToken();
        this.$refs.unityInstance.contentWindow.Get_ProjectId();
        this.$refs.unityInstance.contentWindow.Get_ProjectTypeState();
        return;
      }

      // 触摸测点的弹框
      if (e.data.h_target && e.data.h_evX) {
        console.log("测点触摸");
        this.targetBox = 1; //显示弹框
        this.$bus.$emit("targetHover", e.data.h_target); //触摸传感器，获取到id
        var dom = document.getElementById("targetBox");
        this.$refs.unityInstance.contentWindow.Set_DeviceNum();
        dom.style.left = e.data.h_evX - 230 + "px";
        dom.style.top = e.data.h_evY - 320 + "px";
        return;
      }

      if (e.data.entryQL) {
        console.log("进入单桥111111111111", e.data.entryQL);
        this.projectId = e.data.entryQL;

        // 进入单桥，桥梁列表菜单隐藏
        this.$bus.$emit("handleClose");
        this.warnClearInter();
        // this.projectId = '10008';
        // sessionStorage.setItem('projectId', this.projectId)
        this.targetBox = 0; //隐藏测点详情
        this.all = 0;
        this.win = 0;

        return;
      }

      if (e.data.leaveQL) {
        if (this.$refs["alonelookzhw"]) {
          this.$refs["alonelookzhw"].getClearRate();
        }
        console.log("离开单桥", e.data.leaveQ);
        // this.all = 1;
        this.aloneType = 0; //单桥类型
        this.warnInter();
        return;
      }

      // 点击测点  e.data.clickDevice
      if (e.data.target == "target1") {
        console.log("获取测点id");

        this.closeShow = 1; //打开数据曲线窗口
        return;
      }

      // gis上点击传感器   获取id
      if (e.data.clickDevice) {
        console.log("c_target", e.data.clickDevice);
        this.$bus.$emit("getTargetId", e.data.clickDevice); //发送测点id
        this.closeShow = 1; //打开数据曲线窗口
        // 打开数据弹框 隐藏详情弹框
        this.targetBox = 0; //隐藏测点详情
        return;
      }

      // 触摸gis上桥梁图标
      if (e.data.h_evX) {
        var projectId = e.data.h_qlmessages;
        this.$bus.$emit("getProjectId", projectId); //发送给触摸框id， 通过id获取
        console.log("位置xy", e.data.h_evX, e.data.h_evY);
        return;
      }

      // 点击-- 进入单桥模型
      if (e.data.c_qlmessages) {
        var id = e.data.c_qlmessages;
        console.log("桥梁___id", id);
        this.all = 0;
        this.gisData("flytoql1");
        this.$store.commit("getProjectId", id); //写入状态10008
        this.warnClearInter();
        sessionStorage.setItem("projectId", id);
        return;
      }
    },

    // gis重定向，  放大，缩小
    gisData(name) {
      switch (name) {
        case "resetFly":
          this.$refs.unityInstance.contentWindow.Set_ResetCamera(); //复位
          break;
        case "cameraforward":
          this.$refs.unityInstance.contentWindow.Set_Camera_ReduceDistance(); //缩小
          break;
        case "cameraback":
          this.$refs.unityInstance.contentWindow.Set_Camera_AddDistance(); // 放大

          break;
        case "stateChange": //桥梁状态切换
          if (this.bridgeState === 0) {
            this.bridgeState = 1;
            this.$refs.unityInstance.contentWindow.Set_BridgeState_ViewState(this.bridgeState); //1：测点查看状态
          } else {
            this.bridgeState = 0;
            this.aloneType = 0;
            this.$refs.unityInstance.contentWindow.Set_BridgeState_ViewState(this.bridgeState); // 0:桥梁观察状态
          }
          break;
      }
      this.targetBox = 0; //隐藏测点信息弹框
    },

    //从aloneLook 传过来的id值
    centerTarget(val) {
      if(val){
        this.closeShow = 1
        this.$bus.$emit("getTargetId", val);
      }

      // let fixedpos = "fixedpos";
      // let OIframe = document.getElementById("unity-model"); //监听gis
      // let childDomain = this.srcPath;
      // OIframe.contentWindow.postMessage(
      //   { gis: fixedpos, id: val },
      //   childDomain
      // );
    },

    // 树选中
    handleNodeClick(data) {
      // this.loadingPage.close();
      console.log("树选中", data);
      var id = data.id;
      this.gisData("flytoql1"); //飞入模型
      // 获取桥梁信息
      this.getData(data.id);
      // this.dingwei(data.id); //选中树，定位桥梁位置
    },

    // 桥梁定位
    dingwei(id) {
      console.log("桥梁--id", id);
      let bridgefixedpos = id;
      let OIframe = document.getElementById("unity-model"); //监听gis
      let childDomain = this.srcPath;
      OIframe.contentWindow.postMessage({ gis: "bridgefixedpos", id: bridgefixedpos }, childDomain);
    },

    //按类型查询桥梁类型
    changeType(val) {
      // console.log('val 选择桥梁类型', val);
      if (this.typeAll.includes(val.tid) == true) {
        for (var i = 0; i < this.typeAll.length; i++) {
          if (this.typeAll[i] == val.tid) {
            this.typeAll.splice(i, 1);
          }
        }
      } else {
        this.typeAll.push(val.tid);
      }
    },
    // 取消选中所有监测类型
    offTypeFun(e) {
      this.aloneTypeArr = [];
      if (e) {
        let idArr = [];
        this.aloneData.map((item) => {
          if (item.list && item.list.length) {
            item.list.map((k) => {
              console.log(k);
              idArr.push(k.id);
              this.$refs.unityInstance.contentWindow.Set_BridgeState_DeviceState(k.id, "1");
            });
          }
        });
        this.aloneTypeArr = idArr;
      } else {
        this.aloneData.map((item) => {
          if (item.list && item.list.length) {
            item.list.map((k) => {
              console.log(k);
              this.$refs.unityInstance.contentWindow.Set_BridgeState_DeviceState(k.id, "0");
            });
          }
        });
      }
    },
    // gis 监测类型
    getType(item) {
      // aloneTypeArr
      let deviceState; //设备是否选中 选中1 else 0
      if (this.aloneTypeArr.includes(item.id) == true) {
        for (var i = 0; i < this.aloneTypeArr.length; i++) {
          if (this.aloneTypeArr[i] == item.id) {
            this.aloneTypeArr.splice(i, 1);
            deviceState = "0";
          }
        }
        this.offtype = false;
      } else {
        this.aloneTypeArr.push(item.id);
        deviceState = "1";
      }

      this.$refs.unityInstance.contentWindow.Set_BridgeState_DeviceState(item.id, deviceState);
    },

    // 放大缩小曲线窗口
    largeWin(e) {
      var Dom = document.getElementById("largeWin");
      if (e == 1) {
        Dom.style.width = "100%";
        Dom.style.marginLeft = "0px";
        Dom.style.left = "0px";
      } else {
        Dom.style.width = "1326px";
        Dom.style.marginLeft = "-663px";
        Dom.style.left = "50%";
      }
    },

    // 子传过来的
    getAdd(e) {
      this.closeShow = e; //关闭图表窗口
    },
    getAdd1(e) {
      this.closeVideo = e; //关闭视频窗口
    },

    typeShow() {
      if (this.aloneType == 1) {
        this.aloneType = 0;
        // this.bridgeState = 0;
        this.$refs.unityInstance.contentWindow.Set_BridgeState_ViewState(this.bridgeState);
      } else {
        this.aloneType = 1;
        this.bridgeState = 0;
        this.$refs.unityInstance.contentWindow.Set_BridgeState_ViewState(this.bridgeState);
      }
    },

    handleVideoClose() {
      this.win1 = 0;
    },
    handleClose(e) {
      console.log("点击空白处");
      this.win = 0; //隐藏桥梁弹框
      if (this.win1 == 1) {
        var player = videojs("myVideo");
        player.dispose();
        this.videoList.forEach((item, index) => {
          let player = videojs("myVideoList" + index);
          player.dispose(); //dispose销毁
        });
        this.win1 = 0;
      }
    },

    // 监视器是否显示
    tvShow() {
      if (this.win == 1) {
        this.win = 0;
      }

      // "bid=10008&videoName=null" "http://114.116.93.53:8197/pandect/select/bridge/video"

      // "videoName=11" "http://114.116.93.53:8197/pandect/select/video"     总览视频

      // setTimeout(()=>{
      if (this.win1 == 0) {
        if (this.all == "1") {
          var path = `pandect/select/video`;
          console.log("总览的视频");
        } else {
          console.log("单桥的视频");
          var path = `pandect/select/bridge/video?bid=${this.projectId}&videoName=`;
        }

        console.log("打开");
        this.videoData(path); //获取视频数据
        this.win1 = 1;

        this.getVideo(); //获取萤石云视频
      } else {
        console.log("关闭视频窗口");
        if (this.videoList.length == 0) {
          this.win1 = 0;
          return;
        }

        console.log("关闭");
        this.win1 = 0;
        var player = videojs("myVideo");
        player.dispose();
        this.videoList.forEach((item, index) => {
          let player = videojs("myVideoList" + index);
          player.dispose(); //dispose销毁
        });
      }
      // this.all = 1;
      // }, 200)
    },

    // 修改主视频播放
    changeVideo(item) {
      console.log("item", item);
      this.src = item.videoUrl;
      var player = videojs("myVideo");
      videojs("myVideo").src(this.src);
      player.play();
    },

    // 打开视频弹框窗口
    videoWin() {
      this.tvShow(); //关闭视频
      this.closeVideo = 1; //打开视频弹框  等于1的时间
      // var src = this.src;
      // if( this.closeVideo ==1){
      //      this.closeVideo = 0;
      // }else{
      //      this.closeVideo = 1;
      // }
    },

    changeTarget(item) {
      console.log("item", item);

      this.value = item.videoName; // id
      this.src = item.videoUrl;

      this.tvShow(); //关闭视频
      this.closeVideo = 1; //弹出窗口

      // 关闭主视频
      //  console.log('关闭');
      // var player  = videojs('myVideo');
      // player.dispose();
      // // 重新打开新的视频
      // this.$nextTick(()=>{
      //     // this.src = item.videoUrl;    //播放主视频
      //     var player1  = videojs('myVideo');
      //     player1.play();
      // })
    },

    // 数据请求-------------------------------数据请求-----------------------数据请求
    warnInter() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    warnClearInter() {
      if (this.timer) {
        console.log("清除定时器");
        clearInterval(this.timer);
      }
    },

    // 桥梁统计
    bridgeStatistics() {
      this.$axios
        .get(`${this.baseURL}pandect/select/bridgegroup/count`)
        .then((res) => {
          //  console.log('桥梁数字统计', res);
          // numberList    data: cas: 0 count: 1 ger: 0 length: 600 sus: 1    tar: 0
          var data = res.data.data;
          this.numberList[0].name = "悬索桥（座）";
          this.numberList[0].number = data.sus;
          this.numberList[1].name = "斜拉桥（座）";
          this.numberList[1].number = data.cas;
          this.numberList[2].name = "梁桥（座）";
          this.numberList[2].number = data.ger;
          this.numberList[3].name = "拱桥（座）";
          this.numberList[3].number = data.tar;
          this.numberAll.count = data.count;
          this.numberAll.length = data.length;
        })
        .catch((err) => {});
    },
    // 桥梁树形
    bridgeTreeData() {
      this.$axios
        .post(`${this.baseURL}pandect/select/likename?name=${this.name}`)
        .then((res) => {
          //  console.log('桥梁树形信息', res);
          var data = res.data.data;
          this.data = [];
          data.forEach((item, index) => {
            item.name = item.lineName;
            item.parentId = 0;
            item.list.forEach((item1, index1) => {
              item1.parentId = 1;
            });
          });

          let projectId = sessionStorage.getItem("projectId");
          if (projectId) {
            this.getData(projectId);
          } else {
            this.getData(data[0].list[0].id);
          }

          setTimeout(() => {
            this.data = data;
            console.log("dat桥梁树zhwa", this.data);
          }, 200);
        })
        .catch((err) => {});
    },

    // 设置默认桥梁

    getData(id) {
      console.log("获取信息");

      var data = {
        bid: id,
      };
      this.$axios
        .post(`${this.baseURL}pandect/select/bridge`, qs.stringify(data))
        .then((res) => {
          console.log("桥梁信息", res.data.data);

          this.$store.commit("getProjectName", res.data.data.bridge_name);
          sessionStorage.setItem("projectName", res.data.data.bridge_name);

          this.$store.commit("getProjectCompay", res.data.data.compayName); //桥梁公司名称  getProjectCompay
          sessionStorage.setItem("projectCompay", res.data.data.compayName); //桥梁公司名称  compayName

          this.$store.commit("getProjectId", id); //项目id发送
          sessionStorage.setItem("projectId", id);
          this.projectId = id;
          this.getCoverImg()
          this.$bus.$emit("getProjectId", id);
        })
        .catch((err) => {});
    },

    // 桥梁类型
    // bridgeTypyList() {
    //   this.$axios
    //     .post(`${this.baseURL}pandect/select/bridgetype/list`)
    //     .then((res) => {
    //       console.log('设备类型', res.data.data);
    //       var data = res.data.data;

    //       this.queryType = data;

    //       var arr = [];
    //       this.queryType.forEach((item, index) => {
    //         arr.push(item.tid);
    //       });
    //       this.typeAll = arr;
    //     })
    //     .catch((err) => {});
    // },

    // 视频请求
    videoData(path) {
      // if(this.all = 0){
      //     var name = '培森';
      // }else{
      //     var name = ''
      // }
      // pandect/select/video?videoName=${this.name}
      this.$axios
        .post(`${this.baseURL}${path}`)
        .then((res) => {
          console.log("获取视频数据", res.data.data);
          //  this.videoList = res.data.data;
          var data = res.data.data;
          var arr = [];
          data.forEach((item, index) => {
            var num = item.list;
            num.forEach((item1, index1) => {
              arr.push(item1);
            });
          });
          this.videoList = arr;

          console.log("this.videoList", this.videoList);

          this.src = this.videoList[0].videoUrl; //播放主视频
          this.$nextTick(() => {
            var player = videojs("myVideo");
            player.play();
          });
          this.videoList.forEach((item, index) => {
            //遍历播放播放多个视频
            this.$nextTick(() => {
              let player = videojs("myVideoList" + index);
              player.play();
            });
          });

          this.options = data; //视频搜索下拉框
        })
        .catch((err) => {});
    },

    // 单桥 类型
    aloneTypeData(projectID) {
      // this.$axios.get(`${this.baseURL}synthesis/target/type`).then(res => {       //全部类型
      //  .get(`${this.baseURL}synthesis/project/target/type/${this.projectId}`)
      var that = this;
      this.$axios.get(`${this.baseURL}synthesis/video/${this.projectId}`).then((res) => {
        var resData = res.data.data;
        if (resData && resData.length) {
          this.isShowTvBtn = true;
        } else {
          this.isShowTvBtn = false;
        }
        that.$axios
          .get(`${that.baseURL}synthesis/project/target/type/${projectID}`)
          .then((res) => {
            that.aloneData = res.data.data;
            if (that.isShowTvBtn) {
              that.aloneData.push({
                id: "99",
                name: "安防监控",
                list: [
                  {
                    id: "1",
                    name: "视频监控",
                    icon: "icon-shipinjiankong_white_icon1",
                  },
                ],
              });
            }

            var arr = [];
            that.aloneData.forEach((item, index) => {
              if (item.list && item.list.length) {
                item.list.forEach((item, index) => {
                  arr.push(item.id);
                });
              }
            });
            that.aloneTypeArr = arr; //选中所有
            that.offtype = true;
          })
          .catch((err) => {});
      });
    },

    // 获取单桥传过来的信息
    getAloneWarn(data) {
      console.log("传过来的数据============", data);
      this.warnList[2].number = data.oneWarnCount;
      this.warnList[1].number = data.twoWarnCount;
      this.warnList[0].number = data.threeWarnCount;
      // let OIframe = document.getElementById("unity-model"); //监听gis
      // let childDomain = this.srcPath;
      // OIframe.contentWindow.postMessage(
      //   { gis: "gislist_Updata", data: data.gis },
      //   childDomain
      // );
    },
  },
};
</script>
<style scoped lang="scss">
#targetBox {
  position: absolute;
  width: 456px;
  // height: 380px;
  left: 700px;
}
.targetWin {
  width: 100%;
  height: 296px;
  border: 1px solid #ccc;
  background: #fff;
}
.targetMain {
  width: 100%;
  height: 100%;
}
.imgBox {
  width: 100%;
  height: 196px;
  img {
    width: 100%;
    height: 100%;
  }
}
.color1 {
  font-size: 14px;
  color: #282d32;
}
.color2 {
  font-size: 12px;
  color: rgba($color: #282d32, $alpha: 0.5);
}
.allView {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0px;
  z-index: 1;
}

.model-wrapper {
  flex: 1;
  height: 100%;
  div.img-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    .load-model-wrapper {
      position: absolute;
      right: 30px;
      top: 150px;
      width: 56px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      span {
        font-family: SourceHanSansCN-Medium;
        font-size: 14px;
        font-weight: 500;
        margin-top: 15px;
        letter-spacing: 0;
      }
      span.checked-text {
        color: #d4deff;
      }
      span.normal-text {
        color: #748ace;
      }
    }
  }
  /* height: calc(100% - 10px); */
}
.main {
  width: 100%;
  // height: 100%;
}

.leftTop1 {
  width: 11%;
  /* width: 152px; */
  height: 564px;
  background: rgba($color: #041742, $alpha: 0.7);
  border-radius: 8px;
  border: 1px solid;
  border-image: linear-gradient(300deg, rgba(155, 169, 203, 1), rgba(61, 107, 208, 1)) 1 1;
  position: absolute;
  top: 158px;
  left: 24px;
  z-index: 1;
  padding: 26px 17px;

  .left1 {
    padding-bottom: 10px;
    border-bottom: 1px dashed #3a4a7b;
  }
}

.leftTop2 {
  position: absolute;
  left: 20px !important;
  bottom: 25px;
  z-index: 2;

  li {
    width: 160px;
    height: 40px;
    background: rgba($color: #04153f, $alpha: 0.6);
    border-radius: 20px;
    margin-bottom: 16px;
  }
}

.leftTop22 {
  position: absolute;
  left: 670px;
  bottom: 50px;
  z-index: 2;

  li {
    width: 160px;
    height: 40px;
    background: rgba($color: #04153f, $alpha: 0.6);
    border-radius: 20px;
    margin-bottom: 16px;
  }
}

.left2 {
  margin-top: 22px;

  ul li {
    margin-bottom: 25px;
  }

  ul li:last-child {
    margin-bottom: 0px;
  }
}

// 搜索
.search {
  position: absolute;
  top: 158px;
  right: 24px;
  z-index: 2;
  // width:56px;
  // height: 56px;
  // border-radius: 28px;
  // background: #04153F;
}

// 放大 缩小 搜索
.operation {
  position: absolute;
  top: 100px;
  right: 24px;
  z-index: 1;

  .maxmin {
    margin-top: 38px;
    padding-top: 18px;
    width: 56px;
    height: 156px;
    border-radius: 28px;
    background: rgba($color: #04153f, $alpha: 0.6);
  }

  .yuan {
    // text-align: center;
    // border-radius: 28px;
    // width: 33px;
    // height: 33px;
    // line-height: 33px;
    // background: rgba($color: #CCDAFF, $alpha: 0.2);
  }

  .yuan1 {
    text-align: center;
    border-radius: 28px;
    width: 33px;
    height: 33px;
    line-height: 33px;
    background: rgba($color: #ccdaff, $alpha: 0.2);
  }
}

// 桥梁查询
::v-deep .warningzhw {
  position: absolute;
  right: -328px;
  bottom: 0px;
  z-index: 1;
  width: 318px;
  height: 300px;
  overflow: auto;
  border-radius: 8px;
  border: 1px solid #0c49af;
  background: rgba($color: #04153f, $alpha: 0.7);

  .winTitle {
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px 8px 0px 0px;
    background: linear-gradient(128deg, #03195b 0%, #0c49af 100%);
  }

  .winTitle1 {
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px 8px 8px 8px;
    background: linear-gradient(128deg, #03195b 0%, #0c49af 100%);
  }

  .fu {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;

    .zi {
      position: absolute;
      right: 28px;
      top: 24px;
      padding-left: 12px;
      border-left: 1px solid #3a4a7b;
    }

    .el-input {
      width: 100%;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #2667db;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    div.el-input .el-input__inner:hover,
    div.el-input .el-input__inner:focus {
      border-radius: 20px !important;
      border: 1px solid #2667db;
    }

    div.el-input .el-input__inner {
      border-radius: 20px !important;
    }
  }
}
.home-title-content {
  padding: 0 20px 10px 20px;
}
// 桥梁查询
::v-deep .win {
  position: absolute;
  right: 89px;
  top: 113px;
  z-index: 1;
  width: 318px;
  border-radius: 8px;
  border: 1px solid #0c49af;
  background: rgba($color: #04153f, $alpha: 0.7);

  .winTitle {
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px 8px 0px 0px;
    background: linear-gradient(128deg, #03195b 0%, #0c49af 100%);
  }

  .winTitle1 {
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px 8px 8px 8px;
    background: linear-gradient(128deg, #03195b 0%, #0c49af 100%);
  }

  .fu {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;

    .zi {
      position: absolute;
      right: 28px;
      top: 24px;
      padding-left: 12px;
      border-left: 1px solid #3a4a7b;
    }

    .el-input {
      width: 100%;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #2667db;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    div.el-input .el-input__inner:hover,
    div.el-input .el-input__inner:focus {
      border-radius: 20px !important;
      border: 1px solid #2667db;
    }

    div.el-input .el-input__inner {
      border-radius: 20px !important;
    }
  }
}

::v-deep .win1 {
  position: absolute;
  right: 104px;
  width: 326px;
  top: 140px;
  z-index: 100;
  border-radius: 8px;
  border: 1px solid #0c49af;
  background: rgba($color: #000000, $alpha: 0.4);

  .winTitle {
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px 8px 0px 0px;
    background: linear-gradient(128deg, #03195b 0%, #0c49af 100%);
  }

  .videoBox {
    // margin-left: 5px;
    // margin-right: 5px;
    width: 100%;
    height: 270px;
    object-fit: fill;

    .myVideo-dimensions {
      width: 100%;
      height: 270px;
    }
  }

  .videoBox1Fu {
    width: 100%;
    padding: 8px 5px 10px 5px;
  }

  .video {
    width: 160px !important;
    height: 90px !important;
  }

  .videoBox1 {
    width: 160px;
    height: 90px;
    margin-bottom: 10px;

    .myVideo-dimensions {
      width: 160px;
      height: 90px;
    }
  }

  .el-select {
    width: 100%;
    height: 40px;
    border-radius: 20px;

    .el-input {
      height: 40px;
    }

    .el-input__inner {
      border-radius: 20px !important;
    }
  }
}

// 类型查询
.listSearch {
  position: absolute;
  bottom: 32px;
  right: 32px;
  z-index: 1;
  // width: 40px;
  // height: 40px;
  // background: rgba($color: #04153F, $alpha: 0.6);
  // border: 2px solid rgba(238, 238, 238, 0.66);
  // border-radius: 28px;
}

// 地图2d和3d切换
.changeDitu {
  position: absolute;
  bottom: 92px;
  right: 32px;
  z-index: 1;
}

// 图表弹框
.dataWin {
  position: absolute;
  width: 1326px;
  height: 575px;
  left: 50%;
  margin-left: -663px;
  top: 50%;
  margin-top: -262px;
  z-index: 1;
}

.qiaoType {
  position: absolute;
  bottom: 30px;
  right: 100px;
  z-index: 1;

  .bottom {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
  }

  .ul1 {
    .ul1Title {
      letter-spacing: 0px;
      color: #ccdaff;
    }

    li {
      margin-right: 26px;
      // width: 28px;
      // height: 28px;

      border: none !important;
    }

    li:nth-child(4) {
      margin-right: 0;
    }
  }

  .acLi {
    // width: 28px;
    // height: 28px;
    text-align: center;
    // height: 28px;
    background: #0c49af !important;
    border-radius: 28px;
  }

  .li1 {
    width: 28px;
    height: 28px;
    text-align: center;
    // height: 28px;
    background: #3d4d6e;
    border-radius: 28px;
  }
}

// 视频弹框
.videoWin {
  position: absolute;
  width: 1184px;
  height: 585px;
  z-index: 1;
  left: 50%;
  margin-left: -592px;
  margin-top: -262px;
  top: 50%;
}

.a {
  z-index: 99;
}

.b {
  z-index: -1;
}

#ditu {
  width: 100%;
  height: 100%;
  // position: absolute;
  // top: 0;
  // left: 0;
  // z-index: 0;
}

// 单桥类型弹窗
.aloneType {
  position: absolute;
  bottom: 20px;
  right: 104px;
  width: 326px;
  z-index: 2;
  .off_type {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-top: 10px;
    font-size: 14px;
    color: #507fef;
    margin-bottom: -10px;
    .is-checked {
      .el-checkbox__label {
        span {
          color: #1f61ff !important;
        }
      }
    }
    .el-checkbox {
      .el-checkbox__label {
        span {
          color: #617ab7;
        }
      }
    }
  }
  .itemBox {
    width: 25%;
    /* width: 70px; */
    // margin-right: 28px;
    margin-bottom: 10px;
    letter-spacing: 0px !important;
  }

  .itemBox1 {
    width: 28px;
    height: 28px;
    text-align: center;
    height: 28px;
    background: #3d4d6e;
    border-radius: 28px;
  }

  .itemBox2 {
    width: 28px;
    height: 28px;
    text-align: center;
    height: 28px;
    background: #0c49af;
    border-radius: 28px;
  }

  .itemBox:nth-child(5n) {
    margin-right: 0 !important;
  }

  .top {
    width: 100%;
  }

  .bottom {
    border: none !important;
    padding: 11px 0px !important;
  }

  .bottom_1 {
    border: none !important;
  }
}

.ztop {
  z-index: 1;
  margin-top: 100px;
}

.zbottom {
  z-index: -999;
}

.noVideo {
  padding-top: 12%;
  height: 220px;
  text-align: center;

  img {
    width: 92px;
    height: 80px;
  }
}

.kuandu {
  .gong {
    font-size: 10px;
  }
}
</style>
<style lang="scss">
.tps.el-tooltip__popper {
  background: #04244a !important;
  color: #c3dcf8;
  font-size: 14px;
}
.tps.el-tooltip__popper[x-placement^="left"] .popper__arrow:after,
.el-tooltip__popper[x-placement^="left"] .popper__arrow {
  border-left-color: #04244a;
}
</style>