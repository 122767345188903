<template>
  <!-- 进入bim展示时出现的页面 -->
  <div class="aloneLook">
    <!-- <img src="../../assets/images/all/guanbi_icon.png" class="close" alt="" @click="close" v-if="win == 1" /> -->
    <div class="left1" v-if="win == 1">
      <div class="home-title box justify">
        <span class="home-title-name">详细信息</span>
        <img src="@/assets/images/home/guanbi_button.png" alt="" @click="close" v-if="win == 1" />
      </div>
      <div class="home-title-content1">
        <ul class="box center ul">
          <li v-for="(item, index) in tabList" :key="index" :class="tabActive == item.id ? 'activeColor' : 'nor'" @click="changeTab(item.id)">
            <span>{{ item.name }}</span>
            <el-divider v-if="tabActive == item.id" class="my-bottom-divider"></el-divider>
            <el-divider v-if="index < tabList.length - 1" direction="vertical" class="my-divider"></el-divider>
          </li>
        </ul>
        <div style="padding: 0 10px; height: calc(100% - 78px); overflow: auto; padding-bottom: 20px">
          <!-- 1在线率 4测点预警-->
          <div v-show="tabActive == 1">
            <el-tree
              ref="tree"
              :data="data"
              accordion
              default-expand-all
              :props="defaultProps"
              node-key="id"
              :current-node-key="currentNodeKey"
              :highlight-current="true"
              @node-click="handleNodeClick"
            >
              <span class="custom-tree-node textL" slot-scope="{ node, data }">
                <!-- <span>
                                  {{ data.name }}
                              </span>
                              <span v-if="data.list.length !='0'">({{data.list.length}})</span> -->
                <span class="fuColor">{{ data.fu }}</span>

                <span class="colorTitle1">{{ data.typeName }}</span>

                <span v-show="data.count >= 0">({{ data.count }})</span>

                <span class="colorTitle2" style="margin-top: 10px">
                  {{ data.code }}
                  <span style="padding-left: 30px" v-if="data.lastModifyTime">监测值：{{ data.value }}</span>
                </span>
                <div class="colorTitle3 fs12" style="margin-bottom: 5px">
                  {{ data.lastModifyTime }}
                </div>
                <!-- <span v-if="data.parentId=='0'">({{data.children.length}})</span> -->
              </span>
            </el-tree>
          </div>
          <div v-show="tabActive == 2">
            <div class="box justify line">
              <div>健康监测</div>
              <div>分数</div>
            </div>
            <el-tree
              ref="tree1"
              :data="data1"
              accordion
              :props="defaultProps1"
              node-key="id"
              :default-expanded-keys="defaultExpandKeys"
              :current-node-key="currentNodeKey"
              :highlight-current="true"
              @node-click="handleNodeClick"
            >
              <span class="custom-tree-node textL" slot-scope="{ node, data }" style="width: 100%">
                <!-- <span class="fuColor">{{data.fu}}</span>
                                  <span class="colorTitle1">{{data.typeName}}</span>
                                  <span v-if="data.count">({{data.count}})</span>
                                  <span class="colorTitle2" style="margin-top:10px">
                                      {{ data.name }}
                                  </span>
                                  <div class="colorTitle3 fs12" style="margin-bottom:5px">{{data.lastModifyTime}}</div> -->
                <div class="box justify" style="width: 100%">
                  <span>{{ data.name }}</span>
                  <!-- <span v-if="parentId == '0'" style="color:#ccc">{{data.name}}</span> -->
                  <span style="">{{ data.grade.toFixed(2) }}</span>
                </div>
                <!-- <div>
                                      <div>{{node.name}}</div>
                                  </div> -->

                <!-- <span v-if="data.parentId=='0'">({{data.children.length}})</span> -->
              </span>
            </el-tree>
          </div>
          <div v-show="tabActive == 3">
            <ul class="ul3" style="width: 100%">
              <li class="box center justify li3 line">
                <div>检查日期</div>
                <!-- <div class="textC" style="width: 120px">检查人</div> -->
                <div>分数</div>
              </li>
              <li v-for="(item, index) in data2" :key="index" class="box center justify fs14">
                <div class="textC" style="width: 200px">
                  {{ item.createTime }}
                </div>
                <!-- <div class="textC" style="width: 120px">{{ item.creatorName }}</div> -->
                <div class="textR" style="width: 120px">{{ item.score }}</div>
              </li>
            </ul>
          </div>
          <div v-show="tabActive == 4">
            <el-tree ref="tree" :data="data3" :default-expand-all="true" :props="defaultProps" node-key="id" :current-node-key="currentNodeKey" :highlight-current="true" @node-click="handleNodeClick">
              <span class="custom-tree-node textL" slot-scope="{ node, data }">
                <!-- <span>
                                  {{ data.name }}
                              </span>
                              <span v-if="data.list.length !='0'">({{data.list.length}})</span> -->
                <span class="fuColor">{{ data.fu }}</span>

                <span class="colorTitle1">{{ data.typeName }}</span>

                <span v-show="data.count >= 0">({{ data.count }})</span>

                <span class="colorTitle2" style="margin-top: 10px">
                  {{ data.code }}
                </span>
                <div class="colorTitle3 fs12" style="margin-bottom: 5px">
                  {{ data.lastModifyTime }}
                  <span style="padding-left: 5px" v-if="data.lastModifyTime">监测值：{{ data.value }}</span>
                </div>
                <!-- <span v-if="data.parentId=='0'">({{data.children.length}})</span> -->
              </span>
            </el-tree>
          </div>
        </div>
      </div>
    </div>

    <div class="left2 box center" style="align-items: flex-end">
      <div class="box box_column around pointer" @click="tab(1)">
        <div id="ring1" v-show="!isHavhealthDegree"></div>
        <div class="radar" v-show="isHavhealthDegree">
          <div class="radar_show">{{ online }} %</div>
          <img src="@/assets/images/online/online.png" class="radarImg" />
        </div>
        <div class="textC fs20" style="width: 110px; color: #fff">在线率</div>
      </div>
      <div class="box box_column around pointer" @click="toHavhealth" v-show="isHavhealthDegree">
        <div class="radar">
          <img :src="finalStateImg[finalState]" />
        </div>
        <div class="textC fs20" style="width: 110px; color: #fff">整体健康度</div>
      </div>
      <div class="box box_column around pointer" v-show="isHavCondition" @click="tab(2)">
        <div id="ring2"></div>
        <div class="textC fs20" style="width: 110px; color: #fff">健康监测</div>
      </div>
      <div class="box box_column around pointer" v-show="isHavExamine" @click="tab(3)">
        <div id="ring3"></div>
        <div class="textC fs20" style="width: 110px; color: #fff">检查评分</div>
      </div>

      <div class="box box_column around pointer" @click="tab(4)">
        <div class="leftTop22" style="margin-left: 50px">
          <ul>
            <li v-for="(item, index) in warnList" :key="index" class="box around">
              <div class="box center">
                <div style="width: 16px; height: 22px" class="mR5">
                  <img :src="item.src" alt style="width: 100%; height: 100%" />
                </div>
                <div class="box flexBottom">
                  <div class="fs16" style="margin-right: 6px">
                    {{ item.name }}
                  </div>
                  <div class="fs22">{{ item.number }}</div>
                  <div class="fs12">个</div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="textC fs20" style="width: 110px; margin-left: 70px; color: #fff">超限报警</div>
      </div>
    </div>
    <!-- <div class="nameTitle" >超限预警</div> -->
  </div>
</template>

<script>
import qs from "qs";
export default {
  name: "aloneLook",
  props: {
    warnList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      finalStateImg: [
        require("@/assets/images/healthDegree/1lei.png"),
        require("@/assets/images/healthDegree/2lei.png"),
        require("@/assets/images/healthDegree/3lei.png"),
        require("@/assets/images/healthDegree/4lei.png"),
      ],
      finalState: undefined,
      isShowjianchapingfen: true,
      flag: true,
      projectId: "",
      tabActive: "1",
      win: 0,
      tabList: [
        { id: "1", name: "在线率" },
        { id: "2", name: "健康监测" },
        { id: "3", name: "检查评分" },
        { id: "4", name: "超限报警" },
      ],
      defaultExpandIds: [],
      currentNodeKey: "",
      data: [],
      //桥梁树形
      defaultProps: {
        children: "list",
        label: "name",
      },

      data1: [], //
      data3: [],
      defaultProps1: {
        children: "children",
        label: "name",
      },

      online: [], //在线率
      warnArr: [], //测点预警

      defaultExpandKeys: [], // 健康监测 默认展开节点

      data2: [],
      examineGrade: "", //检查评分
      healthGrade: "", //健康评分
      online: "", //在线图

      isHavCondition: false,
      isHavExamine: false,
      isHavhealthDegree: false,
    };
  },
  //创建
  created() {
    this.$bus.$on("getProjectId", this.handleProjectIdChange);
  },

  //安装
  mounted() {
    this.projectId = sessionStorage.getItem("projectId");
    this.getNavData();

    this.healthy(); //健康指数
    this.interval =  setInterval(() => {
      this.bridge(); //在线率
    }, 60000);
    this.healthyMonitor(); //健康监测
    this.healthyPandect(); //检查评分

    setTimeout(() => {
      // this.treeData();
      // let online = this.online ? this.online : 0;
      // this.$chart1.ring("ring1", r1, online + "%", this.online);
      this.getRate();
    }, 300);
  },
  

  //方法
  methods: {
    handleProjectIdChange() {
      this.projectId = sessionStorage.getItem("projectId");
      this.getNavData();
      this.healthy(); //健康指数
      this.bridge(); //在线率
      this.healthyMonitor(); //健康监测
      this.healthyPandect(); //检查评分
      setTimeout(() => {
        // this.treeData();
        // let online = this.online ? this.online : 0;
        // this.$chart1.ring("ring1", r1, online + "%", this.online);
        this.getRate();
      }, 300);
    },
    toHavhealth() {
      // this.$router.push(`/healthDegree`)
      var zonglanElement = document.getElementsByClassName("zonglan");
      for (var i = 0; i < zonglanElement.length; i++) {
        if (zonglanElement[i].innerHTML.trim() === "健康度评估") {
          zonglanElement[i].parentNode.click();
          break;
        }
      }
    },
    showAloneLook() {
      this.$emit("showAloneLook");
    },
    handleNodeExpand(data) {
      console.log("handlenodeexpan-data", data);
      let flag = false;
      this.defaultExpandIds.some((item) => {
        if (item === data.id) {
          flag = true;
          return true;
        }
      });
      if (!flag) {
        this.defaultExpandIds.push(data.id);
      }
    },
    handleNodeCollapse(data) {
      this.defaultExpandIds.some((item, i) => {
        if (item === data.id) {
          this.defaultExpandIds.splice(i, 1);
        }
      });
      this.removeChildrenIds(data);
    },
    removeChildrenIds(data) {
      const ts = this;
      if (data.list) {
        data.list.forEach((item) => {
          const index = ts.defaultExpandIds.indexOf(item.id);
          if (index > 0) {
            ts.defaultExpandIds.splice(index, 1);
          }
          ts.removeChildrenIds(item);
        });
      }
    },
    getClearRate() {
      if (this.timerRate) {
        clearInterval(this.timerRate);
      }
    },
    getRate() {
      this.bridge();
      // if (this.timerRate) {
      //   clearInterval(this.timerRate)
      // }
      // this.timerRate = setInterval(() => {
      //   this.bridge();
      // }, 10 * 1000)
    },

    //   健康监测  监测评分
    healthy() {
      this.$axios
        .post(`${this.baseURL}pandect/project/grade/${this.projectId}`)
        .then((res) => {
          console.log("健康指数", res);
          this.examineGrade = res.data.data.examineGrade;
          this.healthGrade = res.data.data.healthGrade;
        })
        .catch((err) => {});
    },

    bridge() {
      var data = {
        bid: this.projectId,
      };
      this.$axios
        .post(`${this.baseURL}pandect/select/bridge`, qs.stringify(data))
        .then((res) => {
          var str = res.data.data.onlineRate;
          var data = res.data.data.onlineRateList;
          var arr = [];
          arr.push(data.normal);
          arr.push(data.offline);
          arr.push(data.fault);
          arr[0].fu = "在线";
          arr[0].id = "fuId1";
          arr[1].fu = "离线";
          arr[1].id = "fuId2";
          arr[2].fu = "故障";
          arr[2].id = "fuId3";

          let ids = this.formateIds(arr, [], this.flag);
          if (this.flag) {
            this.defaultExpandIds = JSON.parse(JSON.stringify(ids));
            this.flag = false;
          }
          this.data = arr;

          // 测点预警  one轻度  three严重
          var data = res.data.data.warnList;
          var warnArr = [];
          warnArr.push(data.threeWarnList);
          warnArr.push(data.twoWarnList);
          warnArr.push(data.oneWarnList);
          warnArr[0].fu = "一级超限";
          warnArr[1].fu = "二级超限";
          warnArr[2].fu = "三级超限";

          this.warnArr = warnArr;
          this.data3 = warnArr;

          //默认显示在线率

          var r1 = ["#FFF2E6", "#FFBF74"];
          var r2 = ["#FFF2E6", "#74FF8F"];
          var r3 = ["#FFF2E6", "#74E1FF"];
          var r1bg = "#c8b19266";
          var r2bg = "#B8FFCC66";
          var r3bg = "#B8E1FF66";

          let k = str.replace(/%/g, "");
          k = k ? k : 0;
          if (this.online != k) {
            this.online = k;
            this.$chart1.alongRing("ring1", r1, k + "%", k, r1bg);
            let healthGrade = this.healthGrade ? this.healthGrade : 0;
            // this.examineGrade = 9
            let examineGrade = this.examineGrade ? this.examineGrade : 0;

            if (String(this.examineGrade).indexOf("-") >= 0) {
              this.$chart1.alongRing("ring3", r3, "0分", 0, r3bg);
            } else {
              this.$chart1.alongRing("ring3", r3, examineGrade + "分", examineGrade, r3bg);
            }

            if (String(this.healthGrade).indexOf("-") >= 0) {
              this.$chart1.alongRing("ring2", r2, "0分", 0, r2bg);
            } else {
              this.$chart1.alongRing("ring2", r2, healthGrade + "分", healthGrade, r2bg);
            }

            // this.$chart1.ring("ring2", r2, healthGrade + "分", healthGrade);
          }

          // if (Math.abs(k - this.online) > Number.EPSILON) {
          //   this.online = k
          //   this.$chart1.ring("ring1", r1, k + "%", k);
          // }

          let datalist = {
            ...res.data.data.warnCount,
            gis: res.data.data.gislist,
          };
          this.$emit("getAloneWarn", datalist);
        })
        .catch((err) => {});
    },
    formateIds(arr, ids = [], flag = false) {
      if (Array.isArray(arr)) {
        for (let i = 0; i < arr.length; i++) {
          if (!arr[i].id) {
            arr[i].id = arr[i].typeName || arr[i].name;
          }
          if (flag) {
            ids.push(arr[i].id);
          }

          // console.log('ids', ids)
          if (arr[i].list && Array.isArray(arr[i].list)) {
            let sids = this.formateIds(arr[i].list, ids, flag);
            if (flag) {
              ids.concat(ids, sids);
            }
          }
        }
      }
      // console.log('最后的ids', ids, arr)
      return ids;
    },
    // 健康监测
    healthyMonitor() {
      this.$axios
        .post(`${this.baseURL}pandect/grade/info/${this.projectId}`)
        .then((res) => {
          console.log("健康监测");
          console.log("111111111111111111111111111");
          console.log(res);
          this.data1 = res.data.data;
          this.defaultExpandKeys = [res.data.data[0].id];
        })
        .catch((err) => {});
    },

    // 检查评分
    healthyPandect() {
      this.$axios
        .post(`${this.baseURL}pandect/periodic/info/${this.projectId}`)
        .then((res) => {
          this.data2 = res.data.data;
        })
        .catch((err) => {});
    },

    changeTab(val) {
      this.tabActive = val;
      // if(val ==1){
      //     this.data = this.online;
      // }
      // if(val == 4){
      //     this.data = this.warnArr;
      // }
    },
    // 切换圆形图表
    tab(val) {
      this.win = 1;
      this.tabActive = val;
    },
    // 关闭窗口
    close() {
      this.win = 0;
    },

    handleNodeClick(data) {
      console.log("dada",data);
      if(!data.code){
        return
      }
      if (this.tabActive == 1 || this.tabActive == 4) {
        this.$bus.$emit("centerTarget", data.id);
      }
    },
    treeData() {
      var id = this.projectId;
      var path = `pandect/select/bridge?bid=${id}`;
      this.$axios
        .post(`${this.baseURL}${path}`)
        .then((res) => {
          // 在线率
          var data = res.data.data.onlineRateList;
          var arr = [];
          arr.push(data.normal);
          arr.push(data.offline);
          arr.push(data.fault);
          arr[0].fu = "在线";
          arr[1].fu = "离线";
          arr[2].fu = "故障";
          this.online = arr;

          // 测点预警  one轻度  three严重
          var data = res.data.data.warnList;
          var warnArr = [];
          warnArr.push(data.threeWarnList);
          warnArr.push(data.twoWarnList);
          warnArr.push(data.oneWarnList);
          warnArr[0].fu = "轻度预警";
          warnArr[1].fu = "中度预警";
          warnArr[2].fu = "重度预警";

          this.warnArr = warnArr;
          this.data3 = warnArr;

          //默认显示在线率
          this.data = this.online;
        })
        .catch((err) => {});
    },
    getNavData() {
      var path = `navigation/all/one`;
      this.$axios
        .get(`${this.baseURL}${path}`)
        .then((res) => {
          var navList = res.data.data;
          var navItem = navList.find((item) => {
            return item.name == "健康度评估";
          });
          if (navItem) {
            this.isHavhealthDegree = true;
            var params = {
              projectId: this.projectId,
            };
            this.$axios.get(`${this.baseURL}health/assessment/whole`, { params }).then((res) => {
              if (res.data.status == 200) {
                this.finalState = res.data.data[0].finalState;
              }
            });
          } else {
            this.isHavhealthDegree = false;
          }

          var examineNavItem = navList.find((item) => {
            return item.name == "定期检查";
          });
          if (examineNavItem) {
            this.isHavExamine = true;
          }

          var conditionNavItem = navList.find((item) => {
            return item.name == "状况评估";
          });
          if (conditionNavItem) {
            this.isHavCondition = true;
            if (this.isHavExamine == false) {
              var path = `navigation/all/two/${conditionNavItem.id}`;
              this.$axios.get(`${this.baseURL}${path}`).then((res) => {
                var chNavList = res.data.data;
                var chNavItem = chNavList.find((item) => {
                  return item.name == "定期检查";
                });
                if (chNavItem) {
                  this.isHavExamine = true;
                } else {
                  let indexToRemove = this.tabList.findIndex((item) => item.id == "3");
                  if (indexToRemove !== -1) {
                    this.tabList.splice(indexToRemove, 1);
                  }
                }
              });
            }
          } else {
            this.isHavCondition = false;
            let indexToRemove = this.tabList.findIndex((item) => item.id == "2");
            if (indexToRemove !== -1) {
              this.tabList.splice(indexToRemove, 1);
            }
            let indexToRemove1 = this.tabList.findIndex((item) => item.id == "3");
            if (indexToRemove1 !== -1) {
              this.tabList.splice(indexToRemove1, 1);
            }
          }
        })
        .catch((err) => {});
    },
  },
};
</script>
<style scoped lang="scss">
.close {
  position: absolute;
  top: 106px;
  left: 415px;
  z-index: 99;
}
.left1 {
  position: absolute;
  left: 24px;
  top: 137px;
  width: 450px;
  height: 660px;
  /* overflow-y: auto; */
  border-radius: 8px;
  background: rgba($color: #041742, $alpha: 0.7);
  /* // border: 1px solid; */
  border-image: linear-gradient(300deg, rgba(155, 169, 203, 1), rgba(61, 107, 208, 1)) 1 1;
  opacity: 0.85;
  z-index: 2;
  .home-title-content1 {
    height: calc(100% - 38px);

    // background-image: radial-gradient(circle at 50% 53%, #01042ccc 0%, #0b2d5acc 83%);
  }
}

.left2 {
  position: absolute;
  left: 24px;
  bottom: 30px;
  z-index: 2;
}

#ring1 {
  width: 110px;
  height: 110px;
  margin-right: 54px;
}

#ring2 {
  width: 110px;
  height: 110px;
  margin-right: 54px;
}

#ring3 {
  width: 110px;
  height: 110px;
}

::v-deep .fuColor {
  color: #fff !important;
}
/* ::v-deep .el-tree-node__expand-icon.expanded,
::v-deep .el-tree-node__content>.el-tree-node__expand-icon{
  font-size: 20px;
  color: #CCDAFF;
} */

::v-deep .colorTitle1 {
  /* margin-left: 10px; */
  /* color: #fff !important; */
}

::v-deep .colorTitle2 {
  font-size: 14px;
  //    margin-left: 14px;
  color: #a3b2d6 !important;
}

::v-deep .colorTitle3 {
  /* margin-left: 14px; */
  color: #6f81a5 !important;
}

::v-deep .el-tree-node__children .el-tree-node__content {
  padding-left: 18px !important;
  margin-left: 18px;
}

::v-deep .el-tree > .el-tree-node {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-top: 1px solid rgba($color: #ccdaff, $alpha: 0.29) !important;
}

::v-deep .el-tree > .el-tree-node:nth-child(1) {
  padding-top: 10px !important;
  border-top: none !important;
}

// ::v-deep .el-tree-node +.el-tree-node__content{
//     border-bottom: 1px solid #ccc;
// }

.operation {
  position: absolute;
  right: 24px;
  top: 113px;
}

.maxmin {
  margin-top: 8px;
  padding-top: 18px;
  width: 56px;
  height: 156px;
  border-radius: 28px;
  background: rgba($color: #04153f, $alpha: 0.6);
}

.activeColor {
  color: #fff;
}

.nor {
  color: #ccdaff;
}

.ul {
  height: 58px;
  line-height: 58px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 14px;
  color: #7fb3fa;
  justify-content: center;
  border-bottom: 1px solid rgba(69, 202, 255, 0.21);

  li {
    position: relative;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: 13px;
    margin-right: 13px; */
    letter-spacing: 0px !important;
    font-size: 14px;
    cursor: pointer;
    span {
      width: 65px;
      text-align: center;
    }
    .my-bottom-divider {
      position: absolute;
      bottom: -1.5px;
      left: 0;
      margin: 0;
      width: 65px;
      height: 3px;
      background: #2772f0;
    }
    .my-divider {
      background: #0b3a6d;
    }
  }

  li:first-child .my-bottom-divider {
    width: 50px;
  }
  li:nth-child(4),
  li:first-child {
    width: 20%;
  }
}
.textC,
.textR {
  color: #ccdaff;
}
::v-deep .el-tree {
  background: transparent;

  .el-tree-node__content {
    height: 40px;
  }

  .el-tree-node .el-tree-node__content {
    color: #ccdaff;
  }

  .el-tree-node__content:hover {
    color: #fff;
    background-color: transparent;
  }

  .el-tree-node.is-current > .el-tree-node__content {
    color: #fff;
    background-color: transparent !important; //选中的背景色
  }
}

.ul3 {
  .li3 {
    padding-bottom: 7px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba($color: #ccdaff, $alpha: 0.2);
  }

  li {
    margin-bottom: 15px;
  }
}

.nameTitle {
  position: absolute;
  bottom: 30px;
  left: 703px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  z-index: 2;
}

.nameTitle1 {
  position: absolute;
  bottom: 30px;
  left: 232px;
  font-size: 20px;
  color: #fff;
  z-index: 2;
}

.line {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba($color: #ccdaff, $alpha: 0.3);
}

.radar {
  position: relative;
  width: 110px;
  height: 110px;
  margin-right: 54px;
  img {
    width: 100%;
    height: 100%;
  }
  .radarImg {
    width: 92%;
    height: 92%;
    margin-left: 4px;
    margin-top: 4px;
  }
  .radar_show {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 110px;
    font-weight: 600;
    font-size: 20px;
  }
}
.leftTop22 {
  z-index: 2;
  li {
    width: 160px;
    height: 40px;
    background: rgba($color: #04153f, $alpha: 0.6);
    border-radius: 20px;
    margin-bottom: 16px;
  }
}
</style>
